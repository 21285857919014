<template>
  <div>

    <v-sheet min-height="100%">
      <v-sheet   max-width="1240" width="100%" class="mx-auto pa-6 pt-16">

        <!-- Promo Image-->
        <v-sheet class="wsRounded d-flex justify-center" :color="wsLIGHTCARD">
          <div>
            <h1 class="text-center wsDARKER pa-8 px-auto d-flex justify-center" style="font-size: 42px; max-width: 724px">
              {{ $t('market.home.promo_title') }}
            </h1>

            <img :src="require('@/modules/market/assets/img/market_hero_picture.png')"
                 width="100%" height="auto"
                 style="max-width: 535px;"
                 alt="WeStudy Курс-Маркет"
                 class="d-flex mx-auto"
            >
          </div>


        </v-sheet>

        <!-- Popular Courses -->
        <courses-block
            class="mt-10"
            :title="$t('market.home.blocks.popular')"
            filter="popular"
            old
        />

        <!-- Featured Schools -->
        <featured-schools-block />


      </v-sheet>
    </v-sheet>

    <categories-block />



    <v-sheet min-height="100%">
      <v-sheet   max-width="1240" width="100%" class="mx-auto pa-6">

        <courses-block
            v-for="(block , i) in blocks" :key="i"
            :title="block.text"
            :filter="block.filter"
        />

      </v-sheet>
    </v-sheet>
  </div>


</template>

<script>
import entitiesFunctions from "@/mixins/entitiesFunctions";
import coursesBlock from "@/modules/market/components/Homepage/coursesBlock";
import categoriesBlock from "@/modules/market/components/Homepage/categoriesBlock";
import featuredSchoolsBlock from "@/modules/market/components/Homepage/featuredSchoolsBlock";


export default {
  name: "MarketCourses",
  mixins : [entitiesFunctions],
  components : {
    coursesBlock,
    categoriesBlock,
    featuredSchoolsBlock
  },
  data() {
    return {
      items : []
    }
  },
  computed : {
    blocks() {
      return [
        { text : this.$t('market.home.blocks.new')        , filter : 'isNew'        },
        { text : this.$t('market.home.blocks.elite')      , filter : 'isPremium'     },
        { text : this.$t('market.home.blocks.good_price') , filter : 'isGoodPrice' },
        { text : this.$t('market.home.blocks.free')       , filter : 'isFree'       }
      ]
    }
  },
  methods : {
    selectCourse(course) {
      this.notify(course)
    }
  }

}
</script>

<style scoped>

</style>