<template>
<div class="mb-10">
  <div class="d-flex justify-space-between align-center">
    <div></div>
    <h1  class="text-center">{{ title }}</h1>
    <div class="d-flex align-center">
      <v-sheet
          @click="slideBack"
          :color="wsATTENTION + (slideIndex === 0 ? '66' : '')"
          :class="[ {pointer :slideIndex > 0 }]"
          class="d-flex align-center justify-center mr-2"
          style="border-radius: 14px"
          min-width="32" width="32"
          min-height="32" height="32"
          v-ripple="slideIndex > 0"
      >
        <v-icon dark>mdi-arrow-left</v-icon>
      </v-sheet>

      <v-sheet
          @click="slideNext"
          :color="wsATTENTION + (slideIndex >= items.length - 1  ? '66' : '')"
          :class="[ {pointer :slideIndex < items.length - 1 }]"
          class="d-flex align-center justify-center"
          v-ripple
          style="border-radius: 14px"
          min-width="32" width="32"
          min-height="32" height="32"
      >
        <v-icon dark>mdi-arrow-right</v-icon>
      </v-sheet>

    </div>
  </div>

  <v-sheet
      class="d-flex mt-5 overflow-hidden"
      color="transparent"
      style="position: relative"
      height="328"
  >

    <v-sheet color="transparent" class="fill-height d-flex"  style="transition: all 0.3s ease" :style="sliderMargin">

      <v-sheet
          v-for="(item,i) in items" :key="i"
          :color="wsBACKGROUND"
          :min-width="width"
          :width="width"
          height="100%"
          class="wsRoundedHalf fill-height fill-height d-flex flex-column mr-6"
          style="position : relative;" >

        <v-hover #default="{hover}">
          <v-sheet
              @click="item.expand = !item.expand"
              width="30" height="30"
              :color="hover ? wsATTENTION : wsLIGHTCARD"
              class="d-flex align-center justify-center pointer"
              style=" border-radius : 50%; position : absolute ; right : 8px ; top : 8px"
          >
            <v-icon :color="hover ? '#ffffff' : wsACCENT">
              {{ !item.expand ? 'mdi-information-outline' : 'mdi-close' }}
            </v-icon>
          </v-sheet>
        </v-hover>

        <v-sheet
            v-if="!item.expand"
            :style="`
              background : url(${item.img});
              background-size: cover
              background-position : center
              `"
            style=""
            class="wsRoundedHalfTop d-flex align-center justify-center" :color="wsBACKGROUND" height="186">

          <img v-if="!item.img"
               height="32" width="38" alt="WeStudy"
               style="object-fit: cover;object-position: 0"
               src="https://westudy.ua/library/img/westudy_logo.png" >

        </v-sheet>


        <div v-if="item.expand" class="pa-4 fill-height d-flex align-center">
          <h5 :style="`color : ${wsDARKER}`" class="font-weight-regular">
            {{ item.description }}
          </h5>
        </div>
        <v-hover
            v-if="!item.expand"
            #default="{hover}">
          <ws-link :to="localeLink(`market/courses/${item.uuid}`)">
            <v-sheet
                @click="selectCourse(item)"
                :color="hover ? wsATTENTION : wsLIGHTCARD " class="wsRoundedHalf flex-grow-1 pointer pa-4 "
                :class="[{'mt-n8' : !item.expand}]"
                height="174"
                max-height="174"
            >
              <div class="d-flex flex-column fill-height ">
                <h4 :style="`color : ${hover ? wsWHITE : wsDARKER}`" class="mb-6 shorten-text-two">
                  {{ item.name }}
                </h4>

                <h5 v-if="item.is_payed"
                    :style="`color : ${hover ? wsWHITE : wsDARKER}`"
                >
                  {{ $t('Price') }}: {{item.has_payment_plans ? item.minimum_price : item.price}}</h5>
                <div class="flex-grow-1"></div>

                <div class="d-flex align-center">
                  <v-sheet
                      :color="wsBACKGROUND"
                      class="d-flex align-center justify-center round mr-2"
                      width="40" min-width="40"
                      height="40" min-height="40"
                  >
                    <v-icon :color="wsACCENT">mdi-school-outline</v-icon>
                  </v-sheet>
                  <h5 :class="!hover ? 'wsACCENT' : 'WHITE'" class="font-weight-regular">
                    {{ item.business }}
                  </h5>
                </div>
              </div>
            </v-sheet>
          </ws-link>

        </v-hover>



      </v-sheet>

    </v-sheet>


  </v-sheet>
</div>
</template>

<script>

export default {
  name: "coursesBlock",
  props : {
    title : {
      type : String,
      default : 'Courses',
    },
    filter : {
      type : String,
    },
    props : {
      old : {
        type : Boolean,
        default : false
      }
    }
  },
  data() {
    return {
      items : [],
      width : 260,
      slideIndex : 0
    }
  },
  computed : {

    sliderMargin() {
      return `margin-left : -${this.slideIndex * (this.width +24)}px !important`;
    }
  },
  methods : {
    slideNext() {
      if ( this.slideIndex < this.items.length - 1  ) {
        this.slideIndex++
      }

    },
    slideBack() {
      if ( this.slideIndex >0 ) {
        this.slideIndex--
      }
    },
    async initData() {
      let data = {
        limit : 20,
        [this.filter] : true
      }
      let result
      if (!this.old) {
        result = await this.$store.dispatch("market/GET_COURSES_NEW" , data);
      } else {
        result = await this.$store.dispatch('market/GET_COURSES' , data)
      }

      if ( !result ) {
        return this.ERROR(this.$t('NetworkError'))
      }
      this.items = result
    }
  },
  mounted() {
    this.initData()
  }
}
</script>

<style scoped>

</style>