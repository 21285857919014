<template>
  <div>
    <h1  class="text-center mb-6">{{ $t('market.home.authors.title') }}</h1>
    <div class="d-flex overflow-hidden mb-10">
      <v-sheet
          v-for="i in 5" :key="i + 'school'"
          height="450" width="260" min-width="260" :color="wsATTENTION"
          class="wsRoundedHalf mr-6"
          style="
                background: url('https://www.mrporter.com/cms/ycm/resource/blob/757590/a40e09720194ee2b0ca493523a7ecf39/7442c016-cae0-4a93-9088-7298fd397ba3-data.jpg');
                background-size: cover;
              "
      >

      </v-sheet>
    </div>


  </div>

</template>

<script>
export default {
  name: "categoriesBlock"
}
</script>

<style scoped>

</style>